define("fer-resume/components/bulma-nav-left", ["exports", "ember-bulma/components/bulma-nav-left"], function (_exports, _bulmaNavLeft) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _bulmaNavLeft.default;
    }
  });
});
