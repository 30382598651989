define("fer-resume/components/bulma-hero-footer", ["exports", "ember-bulma/components/bulma-hero-footer"], function (_exports, _bulmaHeroFooter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _bulmaHeroFooter.default;
    }
  });
});
