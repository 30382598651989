import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div ...attributes>\n    <span class=\"small\">\n        <FaIcon @prefix=\"fal\" @icon=\"history\" /> {{this.start}} – {{this.end}}</span>\n</div>", {"contents":"<div ...attributes>\n    <span class=\"small\">\n        <FaIcon @prefix=\"fal\" @icon=\"history\" /> {{this.start}} – {{this.end}}</span>\n</div>","moduleName":"fer-resume/components/date.hbs","parseOptions":{"srcName":"fer-resume/components/date.hbs"}});
import Component from "@glimmer/component";
import { inject as service } from "@ember/service";

export default class DateComponent extends Component {
  @service intl;
  get start() {
    const start = this.intl.formatDate(this.args.startDate)
    return start
  }
  get end() {
    if (this.args.endDate) {
      const end = this.intl.formatDate(this.args.endDate)
      return end
    } else {
      return "present";
    }
  }
}
