define("fer-resume/components/bulma-nav-right", ["exports", "ember-bulma/components/bulma-nav-right"], function (_exports, _bulmaNavRight) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _bulmaNavRight.default;
    }
  });
});
